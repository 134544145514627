import type { TopLevelFormatterParams } from "echarts/types/dist/shared";
import colors from "../../helpers/colors";
import { format } from "date-fns";
import { Grid, Typography } from "@mui/joy";

type TooltipFormatterProps = {
  param: TopLevelFormatterParams;
};

export default function TooltipFormatter({ param }: TooltipFormatterProps) {
  if (!Array.isArray(param) || !Array.isArray(param[0].value)) return "";
  const date = format(param[0].value[0] as string, "dd MMM yyyy");
  return (
    <div
      style={{
        background: "white",
        padding: "10px 15px",
        border: `1px solid ${colors["grey"][300]}`,
        borderRadius: "5px",
      }}
    >
      <Typography
        sx={{ color: colors["grey"][800] }}
        level="body-sm"
        fontWeight={700}
      >
        {date}
      </Typography>

      <Grid
        container
        direction="column"
        sx={{ width: "210px", marginTop: "5px" }}
      >
        {param.map((parameters, index) => {
          if (!Array.isArray(parameters.value)) return "";
          const displayValue =
            Math.round(parseFloat(parameters.value[1] as string) * 100) / 100;

          return (
            <Grid
              container
              key={`${displayValue.toString()}${index.toString()}`}
              justifyContent="space-between"
              alignItems="center"
              direction="row"
            >
              <Grid sx={{ display: "flex", alignItems: "center" }}>
                <span
                  style={{
                    fontWeight: 300,
                    display: "inline-block",
                    width: "10px",
                    height: "10px",
                    background: parameters.color as string,
                    borderRadius: "50%",
                    marginRight: "5px",
                  }}
                ></span>

                <Typography
                  sx={{ color: colors["grey"][800] }}
                  level="body-sm"
                  fontWeight={300}
                >
                  {parameters.seriesName}:
                </Typography>
              </Grid>
              <Grid sx={{ textAlign: "right" }}>
                <Typography
                  sx={{ color: colors["grey"][800] }}
                  level="body-sm"
                  fontWeight={400}
                >
                  {displayValue} m
                </Typography>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}
